import React, { createContext, useState, useEffect } from 'react';
import oceanapi, { fetchThemeDetails } from '../../Base/OceanConfig';
import { getSubdomain, getUserName } from '../../Utils/Common';
import { getPreSignedUrlGetObj } from '../../Utils/preSignedUrl';

const ColorContext = createContext();

const ColorProvider = ({ children }) => {
	const userName = getUserName();
    const subdomain = getSubdomain();
	const [ logo, setLogo ] = useState('');
	const [ primaryColor, setPrimaryColor ] = useState('');
	const [ secondaryColor, setSecondaryColor ] = useState('');

	useEffect(() => {
		themeFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const themeFunction = async () => {

        try {
            const res = await oceanapi.get(fetchThemeDetails + subdomain);
            if (res.data.code === 200) {
                setPrimaryColor(res.data.result.theme.primaryColor);
                setSecondaryColor(res.data.result.theme.secondColor);
                const logoUrl = await getS3ImgUrl(res.data.result.logo);
                const faviconUrl = await getS3ImgUrl(res.data.result.favIcon);

                setLogo(logoUrl);
                changeFavicon(faviconUrl);
            }
        } catch (err) {
            console.error(err);
        }
	}

	const changeFavicon = (link) => {
        const favIcon = document.querySelector("link[rel~='icon']");
        favIcon.href = link;
	}

    const getS3ImgUrl=async(fileName)=>{
        const s3url = await getPreSignedUrlGetObj(fileName, 'oceanService')
        return s3url
    }

    const css = {
        primaryButton: {
            backgroundColor: primaryColor,
            border: `1px solid ${primaryColor}`,
            color: '#FFF'
        },
        secondaryButton: {
            color: primaryColor,
            border: `1px solid ${primaryColor}`
        },
        active: {
            color: secondaryColor,
            borderColor: secondaryColor
        },
        color: {
            color: primaryColor
        },
		menuSelected: {
			background: primaryColor,
            color: '#FFF'
		},
        border: {
            border: `1px solid ${primaryColor}`
        },
        secondaryColor: {
            color: secondaryColor
        }
    };

    return (
        <ColorContext.Provider value={{ logo, primaryColor, setPrimaryColor, setLogo, setSecondaryColor, changeFavicon, userName, subdomain, css }}>
            {children}
        </ColorContext.Provider>
    );
};

export { ColorContext, ColorProvider };