import React, { useState, useEffect, useContext } from 'react';
import adminapi, { getSandboxApis, getSandboxApisStatus, updateSandboxApisStatus } from '../../Base/AdminConfig';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Loading from '../../Components/Loading/Loading';
import styles from './my-apis.module.css';
import Message from '../../Components/Message/Message';

export default function MyAPIs(){
    const { userName } = useContext(ColorContext);
    const [ sandboxApiList, setSandboxApiList ] = useState([]);
    const [ apiList, setApiList ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    useEffect(() => {
        if(userName){
            fetchDefaultFunction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName])

    const fetchDefaultFunction = () => {
        adminapi.get(getSandboxApis)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setSandboxApiList(res.data.apiData);
                fetchApiStatusFunction(res.data.apiData);
            } else {
                setSandboxApiList([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const fetchApiStatusFunction = (apiData) => {
        adminapi.get(getSandboxApisStatus + userName)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setApiList(res.data.apis);
            } else {
                setApiList(getAllKeysFunction(apiData));
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const getAllKeysFunction = (list) => {
        return list.reduce((acc, item) => {
            if (item.services) {
                acc[item.services] = true;
            }
            return acc;
        }, {});
    }

    const updateApiStatusFunction = (key, value) => {
        adminapi.post(updateSandboxApisStatus, {
            userName,
            key: key,
            value: value 
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                fetchApiStatusFunction();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">My APIs</h1>
            <div className="admin-dashboard-body">
                {
                    loading
                    ?<Loading />
                    :<>
                        <div className={styles['plan-head']}>
                            <p>#</p>
                            <p>Category</p>
                            <p>API name</p>
                            <p>Description</p>
                            <p>Action</p>
                        </div>
                        {sandboxApiList.map((item, i) => (
                            <div className={styles['plan-body']} key={i}>
                                <p><span>#</span> {i+1}</p>
                                <p><span>Category:</span> {item.category}</p>
                                <p><span>API name:</span> {item.name}</p>
                                <p><span>Description</span> {item.description}</p>
                                <div>
                                    <span onClick={() => updateApiStatusFunction(item.services, true)}><i className={`fa fa-${!(apiList[item.services]===false) ? 'check-' : ''}circle-o`}></i> Show</span>
                                    <span onClick={() => updateApiStatusFunction(item.services, false)}><i className={`fa fa-${(apiList[item.services]===false) ? 'check-' : ''}circle-o`}></i> Hide</span>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
        </div>
    </>);
}
