import React, { useEffect, useState } from 'react'
import adminapi, { setSecurity, getSecurity, setLandingPageStatus } from '../../../Base/AdminConfig';
import oceanapi, { checkLandingPage } from '../../../Base/OceanConfig';
import { getAdminUser } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function Setting(props) {
    const userId = getAdminUser();
    const userName = props.userName;
    const subdomain = props.subdomain;
    const [serverSecurity, setServerSecurity] = useState(false);
    const [landingPage, setLandingPage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const UpdateSecurity = (value) => {
        setServerSecurity(value)
        adminapi.post(setSecurity, {
            userId: userId,
            userName: userName,
            status: value
        })
        .then((res) => {
            if(res.data.code === 200) {
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const fetchSecurity = () => {
        adminapi.get(getSecurity + userId)
        .then((res) => {
            if(res.data.code === 200) {
                setServerSecurity(res.data.result.status);
            } else {
                setServerSecurity(false);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    useEffect(() => {
        fetchSecurity();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        checkLandingPageStatus();
        // eslint-disable-next-line
    }, [])

    const checkLandingPageStatus = () => {
        oceanapi.get(checkLandingPage + subdomain)
        .then((res) => {
            if(res.data.code === 200) {
                setLandingPage(res.data.result.status);
            } else {
                setLandingPage(false);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const updateLandingPageStatus = (value) => {
        adminapi.post(setLandingPageStatus, {
            status: value
        })
        .then((res) => {
            if(res.data.code === 200) {
                setMessageClass('success');
                setMessage(res.data.message);
                checkLandingPageStatus();
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className="my-custom-container">
            <h5 className={styles['my-account-details-heading']}>Settings</h5>
            <div className='row'>
                <div className='col-xs-12 my-2 col-md-6'>
                    <label className='font-semibold'>Server Security</label><br />
                    <input type="radio" name="serverSecurity" checked={serverSecurity && 'checked'} onClick={() => UpdateSecurity(true)} /> Enable &nbsp; &nbsp;
                    <input type="radio" name="serverSecurity" checked={!serverSecurity && 'checked'} onClick={() => UpdateSecurity(false)} /> Disable
                </div>
                <div className='col-xs-12 my-2 col-md-6'>
                    <label className='font-semibold'>Landing page</label><br />
                    <input type="radio" name="landingPage" checked={landingPage && 'checked'} onClick={() => updateLandingPageStatus(true)} /> Enable &nbsp; &nbsp;
                    <input type="radio" name="landingPage" checked={!landingPage && 'checked'} onClick={() => updateLandingPageStatus(false)} /> Disable
                </div>
            </div>
        </div>
    </>)
}
