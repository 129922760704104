import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import superadminapi, { allAdmin, clientsFilter } from '../../Base/SuperAdminConfig';
import Message from "../../Components/Message/Message";
import styles from './clients.module.css';

export default function Clients(){
    const navigate = useNavigate();
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ clientCounts, SetClientCounts ] = useState(0);
    const [ firstName, setFirstName] =useState("");
    const [ lastName, setLastName] =useState("");
    const [ email, setEmail] =useState("");
    const [ phone, setPhone] =useState("");
    const [ userId, setUserId] =useState("");
    const [ list, setList ] = useState([]);
    const [ filterModal, setFilterModal ] = useState(false);
    const [ filterData, setFilterData ] = useState(false);
    const [ filterList, setFilterList] = useState([]);
    const [ filterLoading, setFilterLoading] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const getAllAdminList= () => {
        superadminapi.get(allAdmin+`?pageNumber=${pageNumber}`)
        .then((res) => {
            if(res.data.code === 200){
                setList(res.data.planDetails);
                SetClientCounts(res.data.totalRecords);
            } else {
                setList([]);
            }
        }).catch((error) => {
            console.log(error);
            
        })
    }
    
    useEffect(() => {
        getAllAdminList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const openClientDetails = (x) => {
        sessionStorage.setItem("client", JSON.stringify(x));
        navigate('/superadmin/client-details');
    }

    const clearFilter = () => {
        setFirstName('');
        setLastName('');
        setUserId('');
        setEmail('');
        setPhone('');
    }

    const filterClients =(e)=>{
        e.preventDefault();
        setFilterLoading(true);
        superadminapi.get(clientsFilter +`?firstName=${firstName}&email=${email}&phoneNumber=${phone}&userId=${userId}`)
        .then((res)=>{
            if(res.data.code === 200) {
                setFilterLoading(false);
                setFilterData(true);
                setFilterList(res.data.result.records);
                setFilterModal(false);
                clearFilter();
            } else {
                setMessageClass('error');
                setMessage(res.data.message)
                setFilterLoading(false);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}  />}
        {filterModal && <div className={styles['filter-container']}>
            <div className={styles['filter-background']} onClick={() => setFilterModal(false)}></div>
            <div className={styles['filter-form']}>   
                <div className={styles['filter-icon']}>
                    <span><img src='../images/filter-arrow.svg' alt='Filter-sign' />Filter<span><img src="../images/cancel.svg" alt="times" onClick ={() => setFilterModal(false)}/></span></span>
                    <hr/>
                    <form onSubmit={filterClients} >
                        <label>First name</label>
                        <input type="text" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        <label>Last name</label>
                        <input type="text" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        <label>Email</label>
                        <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <label>Phone</label>
                        <input type="text" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                        <label>User Id</label>
                        <input type="text" placeholder="User Id"value={userId} onChange={(e) => setUserId(e.target.value)}/>
                        <p className={styles["clear-filter"]} onClick={() => clearFilter()}>Clear</p>
                        {
                            filterLoading
                            ?<button className="btn primary-button mt-3 px-5" disabled><i className="fa fa-circle-o-notch fa-spin"></i> Wait</button>
                            :<button type="submit" className="btn primary-button mt-3 px-5">Apply</button>
                        }
                    </form>
                </div>
            </div>
        </div>}
        <div className={styles['client-container']}>
            <div className={styles['search-box']}>
                <span>Clients List</span>
                <span onClick={() => setFilterModal(true)}><img src='../../images/filter-arrow.svg' alt='Filter-sign'/> Filter</span>
            </div>
            <div className={styles['container-head']}>
                <p>#</p>
                <p>User ID</p>
                <p>Name</p>
                <p>Date</p>
                <p>Email</p>
                <p>Phone No</p>
                <p>Action</p>
            </div>
            {
                filterData
                ?<>
                    {filterList.map((item, i) => (
                        <div className={styles['container-body']} key={i}>
                            <p><span>#</span>{i+1}</p>
                            <img src="../../images/superadmin/profileLogo.svg" alt=""/>
                            <p><span>Date:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                            <p><span>Name: </span>{item.firstName +" " + item.lastName}</p>
                            <p><span>User ID: </span>{item.userId}</p>
                            <p><span>Email: </span>{item.email}</p>
                            <p><span>Phone No: </span>{item.phoneNumber}</p>
                            <p onClick={() => openClientDetails(item)}>View details</p>
                        </div>
                    ))}
                </>
                :<>
                    {list?.map((item, i) => (
                        <div className={styles['container-body']} key={i}>
                            <p><span>#</span>{(pageNumber-1)*50 + (i + 1)}</p>
                            <p><span>User ID: </span>{item.userId}</p>
                            <p><span>Name: </span>{item.firstName +" " + item.lastName}</p>
                            <p><span>Date:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                            <p><span>Email: </span>{item.email}</p>
                            <p><span>Phone No: </span>{item.phoneNumber}</p>
                            <button className="btn primary-button" onClick={() => openClientDetails(item)}>View details</button>
                        </div>
                    ))}
                </>
            }
            {!filterData && <>
                <div className="pagination-box">
                    {
                        pageNumber <= 1
                        ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                        :<span className="fa fa-angle-left pagination-arrow" onClick={() => setPageNumber(pageNumber - 1)}  ></span>
                    }
                    <span> {pageNumber} of {Math.ceil(clientCounts/50)} pages </span>
                    {
                        pageNumber < Math.ceil(clientCounts/50)
                        ?<span className="fa fa-angle-right pagination-arrow" onClick={() => setPageNumber(pageNumber + 1)} ></span>
                        :<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                    }
                </div>
            </>}
        </div>
        {/* <div className={styles['client-container']}>
            {list.map((item, i) => (
                <div className={styles["card-container"]} key={i}>
                    <h5>{item.userName}</h5>
                    <p>{`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                    <div className={styles['card-info']}>
                        <p>
                            <span>User name</span>
                            {item.firstName + " " + item.lastName}
                        </p>
                        <p>
                            <span>email</span>
                            {item.email}
                        </p>
                        <p>
                            <span>User ID</span>
                            {item.userId}
                        </p>
                        <p>
                            <span>Phone</span>
                            {item.phoneNumber}
                        </p>
                    </div>
                    <button className="btn primary-button" onClick={() => openClientDetails(item)}>View Details</button>
                </div>
            ))}
        </div> */}
    </>);
}