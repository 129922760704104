import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ColorProvider } from './Components/ColorContext/ColorContext';
import { getAdminToken, getSuperAdminToken, getSubdomain } from './Utils/Common';
import Layout from './Components/Layout/Layout';
import Home from './Pages/Home/Home';
import Signup from './Pages/Signup/Signup';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import CompleteDetails from './Pages/Signup/CompleteDetails';
import AdminLogin from './Pages/Login/Login';
import AdminDashboard from './Pages/Dashboard/Dashboard'; 
import UserAccounts from './Pages/UserAccounts/UserAccounts';
import UserDetail from './Pages/UserAccounts/UserDetail';
import AddUserManually from './Pages/AddUserManually/AddUserManually';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import UserTransactions from './Pages/UserTransactions/UserTransactions';
import MyTransactions from './Pages/MyTransactions/MyTransactions';
import MyPlan from './Pages/MyPlan/MyPlan';
import MyAPIs from './Pages/MyAPIs/MyAPIs';
import TruBank from './Pages/TruBank/Trubank';
import MyAccount from './Pages/MyAccount/MyAccount';
import AdminLogout from './Pages/Logout/Logout';
import SuperAdminLayout from './SuperAdmin/Components/Layout/Layout';
import SuperAdminLogin from './SuperAdmin/Login/Login';
import SuperAdminDashboard from './SuperAdmin/Dashboard/Dashboard';
import SuperAdminClients from './SuperAdmin/Clients/Clients';
import SuperAdminApis from './SuperAdmin/Apis/Apis';
import SuperAdminTestApis from './SuperAdmin/TestApis/TestApis'
import SuperAdminLogout from './SuperAdmin/Logout';
import SuperAdminUserAccounts from "./SuperAdmin/ClientDetails/ClientDetails"
import Plans from './SuperAdmin/Plans/Plans';
import ForgotPasswordMailVerify from './Pages/Login/ForgotPasswordMailVerify';
import SetNewPassword from './Pages/Login/SetNewPassword';
import WhitelistIp from './Pages/WhitelistIp/WhitelistIp';
import IpHits from './Pages/IpHits/IpHits';

export default function App() {
    const PrivateRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getAdminToken()){
                return true;
            } else {
                return false;
            }
        };

        const isDetailsCompleted = () => {
            if(getSubdomain()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is not authenticated, redirect to the login page
        if (!isUserAuthenticated()) {
            return <Navigate to="/auth" />;
        } else {
            if (!isDetailsCompleted()) {
                return <Navigate to="/complete-details" />;
            }
        }
      
        // Render the private route if the user is authenticated
        return element;
    };

    const PublicRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getAdminToken()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is authenticated, redirect to the dashboard page
        if (isUserAuthenticated()) {
            return <Navigate to="/dashboard" />;
        }
      
        // Render the public route if the user is not authenticated
        return element;
    };

    const PrivateSuperAdminRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getSuperAdminToken()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is not authenticated, redirect to the login page
        if (!isUserAuthenticated()) {
            return <Navigate to="/superadmin/auth" />;
        } 
      
        // Render the private route if the user is authenticated
        return element;
    };

    const PublicSuperAdminRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getSuperAdminToken()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is authenticated, redirect to the dashboard page
        if (isUserAuthenticated()) {
            return <Navigate to="/superadmin/dashboard" />;
        }
      
        // Render the public route if the user is not authenticated
        return element;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/verify-email/:id' element={<VerifyEmail />} />
                <Route path='/reset-password-admin/:token' element={<ForgotPasswordMailVerify />} />
                <Route path='/set-new-password/' element={<SetNewPassword/>} />
                <Route path='/complete-details' element={<CompleteDetails />} />
                <Route path="/auth" element={<PublicRouteWrapper element={<AdminLogin />} />} />
                <Route path="/dashboard" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<AdminDashboard />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/user-accounts" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<UserAccounts />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/add-user-manually" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<AddUserManually />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/reset-password" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<ResetPassword/>} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/user-detail" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<UserDetail />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/user-transactions" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<UserTransactions />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/my-transactions" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<MyTransactions />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/my-plan" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<MyPlan />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/my-apis" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<MyAPIs />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/trubank" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<TruBank />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/my-account" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<MyAccount />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/whitelist-ip" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<WhitelistIp />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route path="/ip-hits" element={
                    <ColorProvider>
                        <Layout>
                            <PrivateRouteWrapper element={<IpHits />} />
                        </Layout>
                    </ColorProvider>
                } />
                <Route
                    path="/logout"
                    element={
                        <PrivateRouteWrapper element={<AdminLogout />} />
                    }
                />
                
                {/* Super admin pages start here */}
                <Route
                    path="/superadmin/auth"
                    element={
                        <PublicSuperAdminRouteWrapper element={<SuperAdminLogin />} />
                    }
                />
                <Route
                    path="/superadmin/dashboard"
                    element={
                        <SuperAdminLayout>
                            <PrivateSuperAdminRouteWrapper element={<SuperAdminDashboard/>}/>
                        </SuperAdminLayout>
                    }
                />
                <Route
                    path="/superadmin/clients"
                    element={
                        <SuperAdminLayout>
                            <PrivateSuperAdminRouteWrapper element={<SuperAdminClients/>}/>
                        </SuperAdminLayout>
                    }
                />
                <Route
                    path="/superadmin/client-details"
                    element={
                        <ColorProvider>
                            <SuperAdminLayout>
                                <PrivateSuperAdminRouteWrapper element={<SuperAdminUserAccounts/>}/>
                            </SuperAdminLayout>
                        </ColorProvider>
                    }
                />
                <Route
                    path="/superadmin/plans"
                    element={
                            <SuperAdminLayout>
                                <PrivateSuperAdminRouteWrapper element={<Plans/>}/>
                            </SuperAdminLayout>
                    }
                />
              
                {/* <Route
                    path="/superadmin/user-accounts"
                    element={
                        <SuperAdminLayout>
                            <PrivateSuperAdminRouteWrapper element={<SuperAdminUserAccounts/>}/>
                        </SuperAdminLayout>
                    }
                /> */}
                <Route
                    path="/superadmin/apis"
                    element={
                        <SuperAdminLayout>
                            <PrivateSuperAdminRouteWrapper element={<SuperAdminApis/>}/>
                        </SuperAdminLayout>
                    }
                />
                <Route
                    path="/superadmin/test-apis"
                    element={
                        <SuperAdminLayout>
                            <PrivateSuperAdminRouteWrapper element={<SuperAdminTestApis/>}/>
                        </SuperAdminLayout>
                    }
                />
                <Route
                    path="/superadmin/logout"
                    element={
                        <PrivateSuperAdminRouteWrapper element={<SuperAdminLogout/>} />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
