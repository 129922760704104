import React from 'react';
import styles from './message.module.css';

export default function Message(props){
    return (
        <div className={styles["message-container"]}>
            {props.messageClass === 'error' && <div className={styles["error-message-box"]}>
                <div>
                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.5 40C31.2696 40 40 31.2696 40 20.5C40 9.73045 31.2696 1 20.5 1C9.73045 1 1 9.73045 1 20.5C1 31.2696 9.73045 40 20.5 40Z" stroke="#FE2343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M26.35 14.6484L14.65 26.3484" stroke="#FE2343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.65 14.6484L26.35 26.3484" stroke="#FE2343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div>
                        <h4>Error!</h4>
                        <h5>{props.message}</h5>
                    </div>
                    <p onClick={props.closeMessage}>Close</p>
                </div>
            </div>}
            {props.messageClass === 'success' && <div className={styles["success-message-box"]}>
                <div>
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 35.75C28.4749 35.75 35.75 28.4749 35.75 19.5C35.75 10.5251 28.4749 3.25 19.5 3.25C10.5251 3.25 3.25 10.5251 3.25 19.5C3.25 28.4749 10.5251 35.75 19.5 35.75Z" stroke="#24B056" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.625 19.5L17.875 22.75L24.375 16.25" stroke="#24B056" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div>
                        <h4>Success</h4>
                        <h5>{props.message}</h5>
                    </div>
                    <p onClick={props.closeMessage}>Close</p>
                </div>
            </div>}
        </div>
    );
}