import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import oceanapi, { getSignupCustomization } from '../../../Base/OceanConfig';
import adminapi, { setSignupCustomization } from '../../../Base/AdminConfig';
import { getAdminUser } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function TermAndConditions(props) {
    const css = props.css;
    const userId = getAdminUser();
    const userName = props.userName;
    const subdomain = props.subdomain;
    const [message, setMessage] = useState('');
    const [termCondition, setTermCondition] = useState('');
    const [forgotPassword, setForgotPassword] = useState(true);
    const [status, setStatus] = useState(true);
    const [loginWithGoogle, setLoginWithGoogle] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [copyright, setCopyright] = useState('');
    const [id, setID] = useState("");
    const [messageClass, setMessageClass] = useState('');
    const [termsModal, setTermsModal] = useState(false);

    const handleSave = () => updateSignUpCustomization();

    const updateSignUpCustomization = () => {
        adminapi.post(setSignupCustomization, {
            id: id,
            userId: userId,
            userName: userName,
            subdomain: subdomain,
            termCondition: termCondition,
            status: status,
            forgotPass: forgotPassword,
            copyRight: copyright,
            createAccount: createAccount,
            loginWithGoogle: loginWithGoogle
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getSignUpCustomization = () => {
		oceanapi.get(getSignupCustomization + subdomain)
		.then(res => {
			if(res.data.code === 200){
                setID(res.data.result._id)
				setStatus(res.data.result.status);
				setLoginWithGoogle(res.data.result.loginWithGoogle);
                setForgotPassword(res.data.result.forgotPass);
                setTermCondition(res.data.result.termCondition);
                setCreateAccount(res.data.result.createAccount);
                setCopyright(res.data.result.copyRight);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

    useEffect(() => {
        getSignUpCustomization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {termsModal && <div className={styles['terms-container']}>
            <div className={styles['terms-background']} onClick={() => setTermsModal(false)}></div>
            <div className={styles['terms-box']}>
                <h2 className={styles['terms-and-conditions-heading']}>Edit term and condition</h2>
                <ReactQuill style={{overflow:"hidden"}} className={styles['html-editor']} theme="snow" value={termCondition} onChange={(html) => setTermCondition(html)}/>
                <p className={styles['terms-and-conditions-note']}>Note: Don't forget to click on apply changes after saving</p>
                <button className="btn px-5" style={css.primaryButton} onClick={() => setTermsModal(false)}>Save</button>
            </div>
        </div>}
        <div className="my-custom-container">
        <h5 className={styles['my-account-details-heading']}>Signup Customization</h5>
            <div className={styles['setting-options']}>
                <div>
                    <p>Forgot password</p>
                    <div>
                        <span onClick={() => setForgotPassword(true)}><i className={`fa fa-${forgotPassword ? 'check-' : ''}circle-o`}></i> Enable</span>
                        <span onClick={() => setForgotPassword(false)}><i className={`fa fa-${!forgotPassword ? 'check-' : ''}circle-o`}></i> Disable</span>
                    </div>
                </div>
                <div>
                    <p>Terms and conditions</p>
                    <div>
                        <span onClick={() => setStatus(true)}><i className={`fa fa-${status ? 'check-' : ''}circle-o`}></i> Enable</span>
                        <span onClick={() => setStatus(false)}><i className={`fa fa-${!status ? 'check-' : ''}circle-o`}></i> Disable</span>
                    </div>
                </div>
                <div>
                    <p>User signup</p>
                    <div>
                        <span onClick={() => setCreateAccount(true)}><i className={`fa fa-${createAccount ? 'check-' : ''}circle-o`}></i> Enable</span>
                        <span onClick={() => setCreateAccount(false)}><i className={`fa fa-${!createAccount ? 'check-' : ''}circle-o`}></i> Disable</span>
                    </div>
                </div>
                <div>
                    <p>Login/signup with Google</p>
                    <div>
                        <span onClick={() => setLoginWithGoogle(true)}><i className={`fa fa-${loginWithGoogle ? 'check-' : ''}circle-o`}></i> Enable</span>
                        <span onClick={() => setLoginWithGoogle(false)}><i className={`fa fa-${!loginWithGoogle ? 'check-' : ''}circle-o`}></i> Disable</span>
                    </div>
                </div>
                <div>
                    <p>Copyright</p>
                    <div>
                        <input type="text" value={copyright} onChange={(e) => setCopyright(e.target.value)} placeholder="Enter copyright"/>
                    </div>
                </div>
                <div>
                    <p>Terms and conditions</p>
                    <div>
                        <span onClick={() => setTermsModal(true)}><i className="fa fa-edit"></i> Edit</span>
                    </div>
                </div>
            </div>
        </div>
        <button className="btn" style={css.primaryButton} onClick={handleSave}>Apply changes</button>
    </>)
}