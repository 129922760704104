import React, { useState, useEffect } from 'react';
import oceanapi, { getDocumentation } from '../../../Base/OceanConfig';
import superadminapi, { setDocumentation } from '../../../Base/SuperAdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../client-details.module.css";

export default function DocumentationLink(props) {
    const userId = props.details.userId;
    const [ message, setMessage ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ messageClass, setMessageClass ] = useState('');
    const [ documentLink, setDocumentLink ] = useState('');

    const getDocumentationLink = () => {
		oceanapi.get(getDocumentation + props.details.userName)
		.then(res => {
			if(res.data.code === 200){
				setDocumentLink(res.data.result.documentLink);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

    useEffect(() => {
		getDocumentationLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
    const addDocumentation = (e) => {
        e.preventDefault();
        setLoading(true);
        superadminapi.post(setDocumentation, {
            userId,
            userName: props.details.userName,
            documentLink: documentLink
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h5 className={styles['documentation-heading']}>Documentation</h5>
        <div className={styles['documentation-body']}>
            <div className={styles['documentaion-body-content']} >
                <form onSubmit={addDocumentation} className={styles['basic-page-form']}>
                    <div>
                        <label>Documentation Link</label>
                        <input type='text' placeholder='Enter Documentation Link' value={documentLink} onChange={e => setDocumentLink(e.target.value)} />
                    </div>
                    <div>
                        {
                            loading
                            ?<button  disabled>wait ...</button>
                            :<button class="btn primary-button" type="submit">Save</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    </>);
}