import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAdminUser} from '../../../Utils/Common';
import oceanapi, { getDocumentation } from '../../../Base/OceanConfig';
import adminapi, { setDocumentation } from '../../../Base/AdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function Basic(props){
    const css = props.css;
    const userName = props.userName;
    const subdomain = props.subdomain;
    const userId = getAdminUser();
    const [ documentLink, setDocumentLink ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ isValid, setIsValid ] = useState(true);

    const copyKey = () => {
        setMessageClass('success');
        setMessage("Copied to clipboard");
        setTimeout(() => {
            setMessage('')
        }, 5000);
    }

    const update = (e) => {
        e.preventDefault();
        addDocumentation();
    }

    const addDocumentation = () => {
        setLoading(true);
        adminapi.post(setDocumentation, {
            userId,
            userName,
            documentLink: documentLink
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getDocumentationLink = () => {
		oceanapi.get(getDocumentation + userName)
		.then(res => {
			if(res.data.code === 200){
				setDocumentLink(res.data.result.documentLink);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}
    
    useEffect(() => {
		getDocumentationLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
  
    const validateURL = (input) => {
      const urlPattern = new RegExp(
        "^https:\\/\\/" + // Mandatory https protocol
          "((([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,}|" + // Domain name
          "localhost|" + // OR localhost
          "\\d{1,3}(\\.\\d{1,3}){3})" + // OR IPv4
          "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._+~#=]*)*" + // Port and path
          "(\\?[;&a-zA-Z0-9@:%._+~#=-]*)?" + // Query parameters
          "(\\#[-a-zA-Z0-9@:%._+~#=]*)?$", // Fragment
        "i"
      );
  
      return !!urlPattern.test(input);
    };
  
    const handleChange = (e) => {
      const inputValue = e.target.value;
      setDocumentLink(inputValue);
      setIsValid(validateURL(inputValue));
    };

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h5 className={styles['my-account-details-heading']}>Basic details</h5>
        <div className='row border-bottom pb-2'>
            {/* <div className='col-xs-12 col-md-6'>
                <label>First Name</label>
                <input type='text' placeholder='Enter first name' disabled value={"Shriyansh"}></input>
                <label>E-mail</label>
                <input type='email' placeholder='Enter the your email' disabled value={"shriyanshagar9026@gmail.com"}></input>
            </div>
            <div className='col-xs-12 col-md-6'>
                <label>Last Name</label>
                <input type='text' placeholder='Enter your last name' disabled value={"Agarwal"}></input>
                <label>Phone Number</label>
                <input type='text' placeholder='Enter phone number' disabled value={"7652093392"}></input>
                <div className={styles['verify-phone-option']}><i className="fa fa-info-circle mr-1"></i> Add &amp; verify phone number!</div>
            </div> */}
            <div className='col-xs-12 col-md-6'>
                <label>User Name</label>
                <input type='text' placeholder='Enter first name' value={userName} disabled/>
            </div>
            <div className='col-xs-12 col-md-6'>
                <CopyToClipboard text={subdomain}>
                    <i className="fa fa-copy float-right cursor-pointer" onClick={copyKey}></i>
                </CopyToClipboard>
                <label>Subdomain</label>
                <input type='text' placeholder='Enter your last name' value={subdomain} disabled/>
            </div>
        </div>
        <div className='row'>      
            <div className='col-xs-12 col-md-12'>
                <form onSubmit={update} className={styles['basic-page-form']} >
                    <div>
                        <label>Documentation Link</label>
                        <input type='text' placeholder='Enter Documentation Link' value={documentLink} onChange={handleChange} />
                    </div>
                    <div>
                        {
                            loading
                            ?<button className="btn" style={css.primaryButton} disabled>wait ...</button>
                            :<>
                                {
                                    isValid
                                    ?<button type="submit" className="btn" style={css.primaryButton}>Save</button>
                                    :<>
                                        <button type="submit" className="btn" style={css.primaryButton} disabled>Save</button>
                                        <span className={styles['invalid-url']}>Invalid URL</span>
                                    </>
                                }
                            </>
                        }
                    </div>
                </form>
            </div>
        </div>
    </>);
}