import React, { useEffect, useState } from 'react'
import adminapi, { addContactDetails } from '../../../Base/AdminConfig';
import oceanapi, { getContactDetails } from '../../../Base/OceanConfig';
import { getAdminUser } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function ContactDetails(props) {
    const userId = getAdminUser();
    const userName = props.userName;
    const subdomain = props.subdomain;
    const css = props.css;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const updateContactDetailsFunction = (e) => {
        e.preventDefault();
        adminapi.post(addContactDetails, {
            userId: userId,
            userName: userName,
            subdomain: subdomain,
            phoneNumber: phoneNumber,
            email: email,
            address: address
        })
        .then((res) => {
            if(res.data.code === 200) {
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    useEffect(() => {
        getContactDetailsFunction();
        // eslint-disable-next-line
    }, [])

    const getContactDetailsFunction = () => {
        oceanapi.get(getContactDetails + subdomain)
        .then((res) => {
            if(res.data.code === 200) {
                setPhoneNumber(res.data.result.phoneNumber);
                setEmail(res.data.result.email);
                setAddress(res.data.result.address);
            } else if(res.data.code !== 404) {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className="my-custom-container">
            <h5 className={styles['my-account-details-heading']}>Settings</h5>
            <form onSubmit={updateContactDetailsFunction}>
                <div className='row'>
                    <div className='col-xs-12 col-md-4'>
                        <label>Phone number</label>
                        <input type='text' placeholder='Enter phone here' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required/>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                        <label>Email</label>
                        <input type='email' placeholder='Enter email here' value={email} onChange={e => setEmail(e.target.value)} required/>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                        <label>Address</label>
                        <input type='text' placeholder='Enter address here' value={address} onChange={e => setAddress(e.target.value)} required/>
                    </div>
                    <div className='col-xs-12 col-md-12 text-right'>
                        <button className="btn" style={css.primaryButton}>Save</button>
                    </div>
                </div>
            </form>
        </div>
    </>)
}
