import React, { useEffect, useState } from "react";
import superadminapi, { adminuserscount, adminapitransaction, adminproducttransaction, admincurrentcredits } from '../../Base/SuperAdminConfig';
import Loading from '../../Components/Loading/Loading';
import styles from './dashbaord.module.css';

export default function Dashboard(){
    const [ loading, setLoading ] = useState(true);
    const [ adminUsersCount, setAdminUsersCount ] = useState(0);
    const [ transactionsCount, setTransactionCount ] = useState(0);
    const [ productTransactionsCount, setProductTransactionCount ] = useState(0);
    const [ currentCredits, setCurrentCredits ] = useState(0);
    
    const counts = [];
    
    const adminusercount = { 
        id: 1,
        image: '../images/superadmin/totalUser.svg',
        name: 'Total Users',
        count: adminUsersCount
    };
    const apitransaction = {
        id: 2,
        image: '../images/superadmin/totalTransaction.svg',
        name: 'Total Transactions',
        count: transactionsCount
    };
    const producttransaction = { 
        id: 3,
        image: '../images/superadmin/accesedProduct.svg',
        name: 'Accessed products',
        count: productTransactionsCount
    };
    const currentcredits = { 
        id: 4,
        image: '../images/superadmin/currentCredits.svg',
        name: 'Current credits',
        count: currentCredits
    };

    counts.push(adminusercount, apitransaction, producttransaction, currentcredits);

    const fetchUsersCount = () => {
        superadminapi.get(adminuserscount)
        .then((res) => {
            if(res.data.code === 200){
                setAdminUsersCount(res.data.result);
                fetchTransactionsCount();
            } else {
                setAdminUsersCount(0);
                fetchTransactionsCount();
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    useEffect(() => {
        fetchUsersCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchTransactionsCount = () => {
        superadminapi.get(adminapitransaction)
        .then((res) => {
            if(res.data.code === 200){
                setTransactionCount(res.data.result);
                fetchProductTransactionsCount();
            } else {
                setTransactionCount(0);
                fetchProductTransactionsCount();
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const fetchProductTransactionsCount = () => {
        superadminapi.get(adminproducttransaction)
        .then((res) => {
            if(res.data.code === 200){
                setProductTransactionCount(res.data.result);
                fetchAdminCurrentCredits();
            } else {
                setTransactionCount(0);
                fetchAdminCurrentCredits();
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }
    const fetchAdminCurrentCredits = () => {
        superadminapi.get(admincurrentcredits)
        .then((res) => {
            if(res.data.code === 200){
                setCurrentCredits(res.data.result);
                setLoading(false);
            } else {
                setTransactionCount(0);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return(
        <>
            {
                loading
                ?<Loading/>
                :<div className={styles['dashboard-counts']}>
                    {counts.map((item, i) => (
                        <div key={i}>
                            <img src={item.image} alt=""/>
                            <div>
                                <p>{item.name}</p>
                                <span>{item.count?.toLocaleString('en-IN')}</span>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}