import React, { useEffect, useState } from 'react';
import superadminapi, { getSandboxStatus, updateSandbox, updateAdminPlan, updateBaseUrl, getCustomBaseUrl } from '../../../Base/SuperAdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../client-details.module.css";

export default function Settings(props) {
    const details = props.details;
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [sandBoxStatus, setSandBoxStatus] = useState(true);
    const [planName, setPlanName] = useState(props.details?.currentPlan?.plan);
    const [updateModal, setUpdateModal] = useState(false);
    const [currentBaseUrl, setCurrentBaseUrl] = useState('');
    const [newBaseUrl, setNewBaseUrl] = useState('');
    const [status, setStatus] = useState(false);
    const [customDetail, setCustomDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const plans = ['default', 'standard', 'premium', 'pro'];

    const fetchSandBoxStatus = () => {
        superadminapi.get(getSandboxStatus +`${details.userId}/${details.userName}`)
        .then((res)=>{
            if(res.data.code === 200) {
                setSandBoxStatus(res.data.doesExist.sendBoxStatus);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
    }

    const handleOnchange = (val)=>{
        setSandBoxStatus(val);
        UpdateSandBoxStatus(val);
    }

    const getCustomBaseUrlFunction =()=>{
        superadminapi.get(getCustomBaseUrl+`/${details.userId}/${details.userName}`)
        .then((res)=>{
            if(res.data.code === 200){
                setCustomDetail(res.data.result);
                setStatus(res.data.result.status)
            }
            else{
                setCustomDetail(null)
            }
        })
    }

    const UpdateSandBoxStatus = (val) => {
        superadminapi.post(updateSandbox,{
            userId: details.userId,
            userName: details.userName,
            sendBoxStatus: val
        })
        .then((res)=>{
            if(res.data.code === 200) {
                setMessageClass('success');
                setMessage(res.data.message);
                fetchSandBoxStatus();
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
    }
    useEffect(() => {
		fetchSandBoxStatus();
        getCustomBaseUrlFunction();
        // eslint-disable-next-line
	}, [])

    const changeAdminPlan = (plan) => {
        superadminapi.post(updateAdminPlan, {
            userName: details.userName,
            plan: plan
        })
        .then((res) => {
            if(res.data.code === 200){
                setPlanName(plan);
                setMessageClass('success');
            } else {
                setMessageClass('error');
            }
            setMessage(res.data.message);
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }).catch((error) => {
            console.log(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const updateBaseUrlFunction =(e)=>{
        e.preventDefault();
        setLoading(true);
        superadminapi.post(`${updateBaseUrl}/${props.details.userId}/${props.details.userName}`,{
            baseUrl:newBaseUrl!== "" ? newBaseUrl : customDetail?.baseUrl,
            oldBaseUrl:currentBaseUrl!== "" ? currentBaseUrl : customDetail?.oldBaseUrl,
            subdomain:"-",
            status: status
        })
        .then((res)=>{
            console.log(res)
            if(res.data.code === 200){ 
                setMessageClass('success');
                setMessage(res.data.message);
                setUpdateModal(false)
            }
            else{
                setMessageClass('error');
                setMessage(res.data.message);
            }
            closeModel()
            setLoading(false);
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
        .catch((err)=>{
            console.log(err);
            setMessage("Something went wrong! Please try agin later");
            setLoading(false);
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const closeModel=()=>{
         setUpdateModal(false);
         getCustomBaseUrlFunction();
    }

    return (
        <>
            {updateModal && 
                <div className={styles['export-container']}>
                    <div className={styles['export-background']} onClick={() => closeModel()}></div>
                    <div className={styles['export-box']}>
                        
                        <>
                            <form onSubmit={updateBaseUrlFunction}>
                                <img src="../images/cancel.svg" alt="times" onClick={() => closeModel()} />
                                <h3 className='mb-4'>Update API Base Url</h3>
                                <label>Current Base url</label>
                                <input
                                    type="text"
                                    value={customDetail? customDetail.baseUrl : currentBaseUrl}
                                    placeholder='Enter Current base url'
                                    onChange={(e) => setCurrentBaseUrl(e.target.value)}
                                    disabled={status === false}
                                    required
                                />
                                <label>New Base Url</label>
                                <input
                                    type="text"
                                    value={newBaseUrl}
                                    placeholder='Enter new base url'
                                    onChange={(e) => setNewBaseUrl(e.target.value)}
                                    disabled={status === false}
                                    required
                                />
                                <label>Status</label>
                                <select value={status ? "true" : "false"}  onChange={(e)=>setStatus(e.target.value ==="true")}>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>

                                {loading ? (
                                    <button className="btn primary-button" disabled>
                                        Submit
                                    </button>
                                ) : (
                                    <button type="submit" className="btn primary-button">
                                        Submit
                                    </button>
                                )}
                            </form>
                        </>
                    </div>
             </div>
            }
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            <h5 className={styles['documentation-heading']}>Settings</h5>
            <div className={styles['setting-grid']}>
                <span>Test api</span>
                <div className= {styles['setting-radio-btn']}>
                    <span><input type="radio" name='status' checked={sandBoxStatus && true} onChange={() => handleOnchange(true)} value='active'/> Enable</span>
                    <span><input type="radio" name='status' checked={!sandBoxStatus && true} onChange={() => handleOnchange(false)} value='inactive'/> Disable</span>
                </div>
            </div>
            <div className={styles['setting-grid']}>
                <span>Change Plan</span>
                <div>
                    <select value={planName} onChange={(e) => changeAdminPlan(e.target.value)} className="mb-0">
                        {plans.map((item, i) => (
                            <option value={item} key={i}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={styles['setting-grid']}>
                <span>Update API Base Url</span>
                <div>
                    {/* <select value={planName} onChange={(e) => changeAdminPlan(e.target.value)} className="mb-0">
                        {plans.map((item, i) => (
                            <option value={item} key={i}>{item}</option>
                        ))}
                    </select> */}
                    <button className='btn primary-button' onClick={()=>setUpdateModal(true)}>
                        Update
                    </button>
                </div>
            </div>
        </>
    )
}