import React, { useState, useEffect, useContext } from 'react';
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
import adminapi, { getSandboxApis, getSandboxApisStatus, updateSandboxApisStatus } from '../../../Base/AdminConfig';
import Message from '../../../Components/Message/Message';
import Loading from '../../../Components/Loading/Loading';
import styles from "../user-accounts.module.css";

export default function APIStatus(props) {
    const userId = props.details.userId;
    const { userName } = useContext(ColorContext);
    const [ sandboxApiList, setSandboxApiList ] = useState([]);
    const [ apiList, setApiList ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ updateLoader, setUpdateLoader ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    useEffect(() => {
        if(userName){
            fetchDefaultFunction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName])

    const fetchDefaultFunction = () => {
        adminapi.get(getSandboxApis)
        .then((res) => {
            if(res.data.code === 200){
                setSandboxApiList(res.data.apiData);
                fetchApiStatusFunction(res.data.apiData);
            } else {
                setLoading(false);
                setSandboxApiList([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const fetchApiStatusFunction = (apiData) => {
        adminapi.get(getSandboxApisStatus + userName + '/' + userId)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setApiList(res.data.apis);
            } else {
                setApiList(getAllKeysFunction(apiData));
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const getAllKeysFunction = (list) => {
        return list.reduce((acc, item) => {
            if (item.services) {
                acc[item.services] = true;
            }
            return acc;
        }, {});
    }

    const updateApiStatusFunction = (key, value) => {
        setUpdateLoader(true);
        adminapi.post(updateSandboxApisStatus, {
            userId: userId,
            userName,
            key,
            value
        })
        .then((res) => {
            setUpdateLoader(false);
            if(res.data.code === 200){
                fetchApiStatusFunction();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            }
        })
        .catch((error) => {
            setUpdateLoader(false);
            console.error(error);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {updateLoader && <div className={styles['update-loader']}><Loading /></div>}
        {
            loading
            ?<Loading />
            :<>
                <div className={styles['plan-head']}>
                    <p>#</p>
                    <p>Category</p>
                    <p>API name</p>
                    <p>Description</p>
                    <p>Action</p>
                </div>
                {sandboxApiList.map((item, i) => (
                    <div className={styles['plan-body']} key={i}>
                        <p><span>#</span> {i+1}</p>
                        <p><span>Category:</span> {item.category}</p>
                        <p><span>API name:</span> {item.name}</p>
                        <p><span>Description</span> {item.description}</p>
                        <div>
                            <span className={styles[!(apiList[item.services]===false) ? 'visible' : '']} onClick={() => updateApiStatusFunction(item.services, true)}><i className={`fa fa-${!(apiList[item.services]===false) ? 'check-' : ''}circle-o`}></i> Show</span>
                            <span className={styles[!(apiList[item.services]===false) ? '' : 'hidden']} onClick={() => updateApiStatusFunction(item.services, false)}><i className={`fa fa-${(apiList[item.services]===false) ? 'check-' : ''}circle-o`}></i> Hide</span>
                        </div>
                    </div>
                ))}
            </>
        }
    </>);
}