import axios from "axios";
export const baseurl = process.env.REACT_APP_WHITELABEL_URL;

const oceanapi = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default oceanapi;

// User login
export const userLogin = "/whitelistUserLogin";

// User registration
export const userRegistration = "/whiteLevelUserRegistration";

// User email verification
export const userEmailVerification = "/user-email-verification/";

// Fetch user name from domain
export const getUserName = "/getUserName/";

// Fetch user details
export const fetchUsers = "/admin/whitelistUserByUserId/";

// Login admin
export const loginAdmin = "/admin/whitelistLogin";

// Login Superadmin
export const loginSuperAdmin = "/super-admin/whitelistLogin";

// SignUp Basic Details Admin
export const adminDetails = "/admin/whilteLevel/registration";

// Verify Email Admin
export const verifyAdminEmail = "/email-verification/";

// Fetch theme details
export const fetchThemeDetails = "/admin/profile/";

// Fetch overall api logs of all users in admin
export const fetchUserTransactions = "/admin/getUserTransactions/";

// Fetch overall api transactions of all users in admin
export const fetchUserApiTransactions = "/admin/allUsers/apiTransactions/";

// Fetch overall product logs of all users in admin
export const fetchUsersProductLogs = "/admin/allUsers/productsTransactionLogs/";

// Fetch overall product transactions of all users in admin
export const fetchUsersProductTransactions = "/admin/allUsers/productTransactions/";

// Change theme logo and color
export const uploadThemeDetails = "/admin/uploadLogo";

// Enter SMTP Details 
export const smtpDetails = "/admin/smtpSettings";

// Fetch count of transactions
export const countTransaction = "/admin/countTransaction/";

// Fetch count of users
export const countUsers = "/admin/countUsersByUserName/";

// Fetch api wallet credits
export const fetchCredits = "/admin/getUserCredits/";

// Fetch product wallet credits
export const fetchProductCredits = "/admin/getUserProductCredits/";

// Add credits in api wallet
export const addCredits = "/admin/user/addCredits";

// Add credits in product wallet
export const addProductCredits = "/admin/user/productAddCredits";

// Fetch API logs of user
export const fetchApiLogs = "/user/getUserTransactions/";

// Fetch API transations of user
export const fetchApiTransactions = "/getUserApiTransactions/";

// Fetch product transations of user
export const fetchProductTransactions = "/getUserProductTransactions/";

// Fetch product logs of user
export const fetchProductLogs = "/getUserProductLogs/";

// Update SubDomain
export const subdomainUpdation = "/admin/updateSubdomain";

// Get SMTP Details
export const getdetailsmtp = "/admin/getSmtpSettings/";

// fetch api prices set for a user
export const getUserPrices = "/admin/getPricesUser/";

// Change the price of api for a user
export const changePrice = "/admin/userPrice/services";

// Landing page contact form
export const postContactForm = "/admin/contactForm";

// Fetch plan and default prices of a user
export const adminPlan = "/admin/defaultPrices/";

//Get API Price 
export const customDefaultPrice= "admin/customDefaultPrices/"

// Check if the user has created subdomain or not
export const checkSubDomain = "/checkSubDomain/";

//  Change Status in Trubank
export const changestatusManualVerification = "/truBankManualVerificationAdmin"

//updating the theme of the admin 
export const updateTheme="/admin/updateProfile";

// Get address name and address of user
export const trulogAddressUrl = "/getAddress/";

// Export csv of a single user
export const exportUserTransactions = "/user/export/transactions/?";

// Export Procduction csv
export const exportUserproductionTransactions = "/user/export/productTransactions/?";

// Add and Update API's for sandbox

///Add API sandbox
export const addApiSendBox="/addApiSendBox/";

//Update API sandbox
export const updateApiSendBox ="/updateApiSendBox/";

//Get all API's sandbox
export const getApiSendBox = "/getApiSendBox/";

//Delete API's sandbox
export const deleteApiSendBox ="/deleteApiSendBox/";

//Superadmin

//AdminList
export const adminList = "/superAdmin/adminList";

// fetch documentation Link
export const getDocumentation = "/user/documentLink/";

//Forgot Password
//Send email for verification
export const mailSent = "/admin/mailSent/forgotPassword";

//decrypt email
export const decryptEmail = "/decryptMailStr/";

//Set New Password
export const setPassword = "/admin/setPassword";

//Set Signup customization
export const getSignupCustomization = "/admin/signUpCustomization/";

// Check landing page
export const checkLandingPage = "/admin/checkLandingPage/";

// To get contact details of an admin
export const getContactDetails = "/admin/contact-details/";
