import React, { useState, useEffect } from 'react';
import superadminapi, { fetchAdminCredits, fetchAdminProductCredits, adminAddProductCredit, adminAddApiCredit } from '../../../Base/SuperAdminConfig';
import Message from '../../../Components/Message/Message';
import styles from '../client-details.module.css';

export default function AddCredits(props) {
    const [apiAmount, setAPiAmount] = useState('');
    const [productAmount, setProductAmount] = useState('');
    const [apiBalanace, setAPiBalance] = useState(0);
    const [productBalance, setProductBalance] = useState(0);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const plan = props.details.currentPlan.plan

    const getCredits = () => {
        superadminapi.get(fetchAdminCredits + props.details.userId)
        .then((res) => {
            if(res.data.code === 200){
                setAPiBalance(res.data.response.amount!==null?res.data.response.amount: 0);
                // setPlan(res.data.response.plan);
            } else {
                setAPiBalance(0);
            }
        }).catch((error) => {
            console.log(error);
        })

        superadminapi.get(fetchAdminProductCredits + props.details.userId)
        .then((res) => {
            if(res.data.code === 200){
                setProductBalance(res.data.response.amount!==null?res.data.response.amount: 0);
                // setPlan(res.data.response.plan);
            } else {
                setProductBalance(0);
            }
        }).catch((error) => {
            console.log(error);
          
        })
    }

    const addProductCredit = (e) => {
        e.preventDefault();
        superadminapi.post(adminAddProductCredit,{
            userId: props.details.userId,
            amount: parseInt(productAmount),
            userName: props.details.userName,
            plan: plan
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
                getCredits();
            } else {
                setMessageClass('error');
                setMessage("Something went wrong, Try again!.");
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        }).catch((error) => {
            console.log(error);
          
        })
    }

    const addApiCredit = (e) => {
        e.preventDefault();
        superadminapi.post(adminAddApiCredit, {
            userId: props.details.userId,
            amount: parseInt(apiAmount),
            userName: props.details.userName,
            plan: plan
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
                getCredits();

            } else {
                setMessageClass('error');
                setMessage("Something went wrong, Try again!.");
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    
    useEffect(() => {
        getCredits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
     {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        <h4>Add credits</h4>
        <div className={styles['credit-outer-container']}>
            <div className={styles['api-wallet']}>API Wallet</div>
            <div className={styles['api-credit-tab']}>
                <div className={styles['balance-tab']}>
                    <h3>Balance</h3>
                    <span >₹{apiBalanace.toLocaleString("en-IN")}</span>
                </div>
                <div className={styles['credit-tab']}>
                    <h3>Add Credits</h3>
                    <form onSubmit={addApiCredit} >
                        <input type='text' placeholder='Enter Amount here' value={apiAmount} onChange={e => setAPiAmount(e.target.value)}/>
                        <button class="btn primary-button" style={{backgroundColor: "#FF7D90",border: `1px solid #6091E2`,color: '#FFF'}}>Add</button>
                    </form>
                </div>
            </div>
            <br/>
            <div className={styles['api-wallet']}>Product Wallet</div>
            <div className={styles['api-credit-tab']}>
                <div className={styles['balance-tab']}>
                    <h3>Balance</h3>
                    <span >₹{productBalance.toLocaleString("en-IN")}</span>
                </div>
                <div className={styles['credit-tab']}>
                    <h3>Add Credits</h3>
                    <form onSubmit={addProductCredit} >
                        <input type='text' placeholder='Enter Amount here' value={productAmount} onChange={e => setProductAmount(e.target.value)}/>
                        <button class="btn primary-button" >Add</button>
                    </form>
                </div>
            </div>

        </div>
    </>)
}