import React, { useState, useEffect } from "react";
import superadminapi, { planDetail, getCustomizeAdminPlan, changeCustomizeAdminPlan, defaultPrice } from '../../../Base/SuperAdminConfig';
import Message from "../../../Components/Message/Message";
import Loading from "../../../Components/Loading/Loading";
import styles from '../client-details.module.css';

export default function APIPrice(){
    const [ userDetail, setUserDetail ] = useState([]);
    const [ planType, setPlanType ] = useState([]);
    const [ changedList, setChangedList ] = useState([]);
    const [ customizedList, setCustomizedList ] = useState([]);
    const [ list, setList ] = useState([]);
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    // const [ productServices, setProductServices] = useState([]);
    const [ planServices, setPlanServices] = useState([]);
    const [ defaultPrices, setDefaultPrices] = useState([]);
    
    useEffect(() => {
        getDefaultPrice()
        if(sessionStorage.getItem("client")){
            fetchPlanDetails(JSON.parse(sessionStorage.getItem("client")));
            // fetchCustomizePlanDetails(JSON.parse(sessionStorage.getItem("client")));
            setUserDetail(JSON.parse(sessionStorage.getItem("client")));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDefaultPrice = () => {
        superadminapi.get(defaultPrice)
        .then((res) => {
            setPageLoading(false);
            if(res.data.code === 200){
                setDefaultPrices(res.data.result)
            }
        }).catch((error) => {
            console.log(error);
            setPageLoading(false);
        })
    }
 
    const fetchPlanDetails = (x) => {
        superadminapi.get(planDetail + x.userId + '/' + x.userName)
        .then((res) => {
            setPageLoading(false);
            if(res.data.code === 200){
                setPlanType(res.data.result.type);
                setList(priceFunction(res.data.result[res.data.result.type], res.data.result.type));
                setPlanServices(res.data.result[res.data.result.type]);
                // setProductServices(res.data.result.products);
                fetchCustomizePlanDetails(x,priceFunction(res.data.result[res.data.result.type], res.data.result.type))
            } else {
                setList([]);
            }
        }).catch((error) => {
            console.log(error);
            setPageLoading(false);
        })
    }

    const fetchCustomizePlanDetails = (x,listtt) => {
        superadminapi.get(getCustomizeAdminPlan + x.userId + '/' + x.userName)
        .then((res) => {
            setPageLoading(false);
            if(res.data.code === 200){
                setChangedList(priceFunction(res.data.result[res.data.result.type], res.data.result.type));
                setCustomizedList(priceFunction(res.data.result[res.data.result.type], res.data.result.type));
                
            } else {
                setChangedList(listtt)
                setCustomizedList(list);
            }
        }).catch((error) => {
            console.log(error);
            setPageLoading(false);
        })
    }

    const priceFunction = (b, c) => {
        var newArray = [];
        Object.keys(b).map(x => (
            newArray.push({
                id: newArray.length + 1,
                name: x,
                price: b[x],
                parent: c,
                nesting: 1
            })
        ))
        // Object.keys(a).map(item => (
        //     Object.keys(a[item]).map(x => (
        //         newArray.push({
        //             id: newArray.length + 1,
        //             name: x,
        //             price: a[item][x],
        //             parent: item,
        //             nesting: 2
        //         })
        //     ))
        // ))
        return newArray;
    }

    const fetchChangedPrice = (x,byDefaultPrice) => {
        const array = customizedList.filter(item => item.name === x);
        if(array.length > 0 && byDefaultPrice!== array[0].price){
            return array[0].price
        } else {
            return '-'
        }
    }

    const fetchPid =(x)=>{
        const array = defaultPrices.filter(item => item.services === x);
        if(array.length > 0){
            return array[0].PID;
        } else {
            return '-';
        }
    }

    const changePrices = (x, y) => {
        const array = changedList.filter(item => item.id === x.id);
        const index=array[0]?.id
        changedList?.splice(index - 1, 1, { ...array[0], price: Number(y) })
        // changedList.splice(array[0].id - 1, 0, { ...array[0], price: Number(y) })
        setChangedList(changedList);
    }

    const fetchPrice = (x) => {
        const array = changedList.filter(item => item.name === x);
        if(array.length > 0){
            return array[0].price;
        } else {
            return '-';
        }
    }

    const planArrayToObject = () => {
        return planOutputObject.reduce((acc, key) => {
            
            acc[key] = fetchPrice(key);
            return acc;
        }, {});
    };

    // const productArrayToObject = () => {
        
    //     const result = productOutputObject.reduce((acc, item) => {
    //         const { name, ...rest } = item;
    //         const restWithFetchPrice = Object.keys(rest).reduce((obj, key) => {
    //             obj[key] = fetchPrice(key);
    //             return obj;
    //           }, {});
    //         acc[name] = restWithFetchPrice;         
    //         return acc;
    //       }, {});
      
    //       return(result)
     
    //   }

    const saveChangedPrices = () => {
        setLoading(true);
        let formattedPlan = Object.fromEntries(
            Object.entries(planArrayToObject()).filter(([key, value]) => value !== "-")
        );
        superadminapi.post(changeCustomizeAdminPlan, {
            userId: userDetail.userId,
            userName: userDetail.userName,
            type: planType,
            [planType]:formattedPlan,
    
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                fetchCustomizePlanDetails(JSON.parse(sessionStorage.getItem("client")));
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    var planOutputObject
    if (JSON.stringify(planServices) !== '{}') {
        planOutputObject = Object.keys(planServices).map((key,index) => {
        return key;
        }, {});
    }

    // var productOutputObject
    // if(JSON.stringify(productServices)!=='{}') {
    // productOutputObject = Object.entries(productServices).map(([key, value]) => ({
    //         name: key,
    //         ...value,
    //     }));
    // }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {/* <h4>Plan details</h4> */}
        {
            pageLoading
            ?<Loading />
            :<>
                <div className={styles['api-price-page']}>
                    <div className={styles['api-price-table']}>
                        <div>
                            <p>#</p>
                            <p>Name</p>
                            <p>PID</p>
                            <p>Plan price</p>
                            <p>Changed price</p>
                        </div>
                        {list.map((item, i) => (
                            <div key={i}>
                                <p><span>#</span> {item.id}</p>
                                <p><span>Name</span> {item.name}</p>
                                <p><span>PID</span> {fetchPid(item.name)}</p>
                                <p><span>Plan price</span> {item.price}</p>
                                <p><span>Changed price</span> {fetchChangedPrice(item.name,item.price)}</p>
                                <p><input type="number" onChange={(e) => changePrices(item, e.target.value)} /></p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles["api-price-action"]}>
                    <button className="btn primary-button" onClick={() => saveChangedPrices()}>{loading?'Wait...':'Save'}</button>
                </div>
            </>
        }
    </>);
}