import React, { useState, useEffect } from "react";
import oceanapi, {
  addOrUpdateServiceAndPrice,
  fetchSuperAdminPlans,
} from "../../Base/SuperAdminConfig";
import Loading from "../../Components/Loading/Loading";
import Message from "../../Components/Message/Message";
import AddNewService from "./Components/AddNewService";

export default function Plans() {
  const [slab, setSlab] = useState(1);
  const [plans, setPlans] = useState("");
  const [prices, setPrices] = useState({});
  const [editPrice, setEditPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const popupController = () => {
    setShowPopup(!showPopup);
  };

  const fetchPlans = (planType) => {
    setLoading(true);
    oceanapi
      .get(fetchSuperAdminPlans + planType)
      .then((res) => {
        if (res.data.code === 200) {
          setPlans(res.data?.result);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ammendPlans = (planType, serviceName, price) => {
    setLoading(true);
    if (planType === "") {
      setMessage("Plan Type is required");
      setMessageClass("error");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
      return;
    }
    if (serviceName === "") {
      setMessage("Service Name is required");
      setMessageClass("error");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
      return;
    }

    if (price === "") {
      setMessage("Price is required");
      setMessageClass("error");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
      return;
    }

    if (price < 0) {
      setMessage("Price cannot be negative");
      setMessageClass("error");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
      return;
    }
    if (isNaN(price)) {
      setMessage("Price must be a number");
      setMessageClass("error");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      setLoading(false);
      return;
    }
    oceanapi
      .post(
        addOrUpdateServiceAndPrice,
        { type: planType, serviceName: serviceName, price: price },
        config
      )
      .then((res) => {
        if (res.data.code === 200) {
          setMessage(res.data.message);
          setMessageClass("success");
          setEditPrice(null);
          setPrices({});
          if (showPopup === true) {
            setShowPopup(false);
          }
          setSlab(1);
          fetchPlans("default");
          setTimeout(() => {
            setMessage("");
          }, 1000);
        } else {
          setMessage(res.data.message);
          setMessageClass("error");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePriceChange = (e, planKey) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [planKey]: e.target.value,
    }));
  };
  const navHandler = (value, planType) => {
    setSlab(value);
    fetchPlans(planType);
    setEditPrice(null);
    setPrices({});
  };

  const handleEditPrice = (planKey) => {
    setEditPrice(planKey);
  };

  useEffect(() => {
    const planType = "default";
    fetchPlans(planType);
  }, []);

  return (
    <>
      {message && (
        <Message
          message={message}
          messageClass={messageClass}
          closeMessage={() => setMessage("")}
        />
      )}
      {showPopup && (
        <AddNewService
          popupController={popupController}
          ammendPlans={ammendPlans}
        />
      )}
      <div className="p-2">
        <div className="p-2 flex justify-between">
          <h5 className="p-2 text-[22px] font-[600]">Plans</h5>
          <button
            className="p-2 btn primary-button"
            onClick={() => popupController()}
          >
            Add New Service
          </button>
        </div>
        <div className="rounded-md grid grid-cols-4 mb-3 border-[#6091E2] p-1 border-[1px] w-[650px] boxShadow: {'custom': '0px 0px 15px 0px #0000001A',}">
          <div
            onClick={() => {
              navHandler(1, "default");
            }}
            className={`${
              slab === 1 ? "bg-[#6091E2] border-[1px] text-white border-x-white" : "border-none"
            } text-center p-2  hover:bg-[#e1ebfa] hover:text-black cursor-pointer rounded-md`}
          >
            <h5 className="text-[14px] max-md:text-[16px] font-[500] ">
              Slab 1 <span className="text-[14px] max-md:text-[12px]">(Default)</span>
            </h5>
            
          </div>
          <div
            onClick={() => {
              navHandler(2, "standard");
            }}
            className={`${
              slab === 2 ? "bg-[#6091E2] border-[1px] text-white border-x-white" : "border-none"
            } text-center p-2  hover:bg-[#e1ebfa] hover:text-black cursor-pointer rounded-md`}
          >
            <h5 className="text-[14px] max-md:text-[16px] font-[500]">
              Slab 2 <span className="text-[14px] max-md:text-[12px]">(Standard)</span>
            </h5>
          </div>
          <div
            onClick={() => {
              navHandler(3, "premium");
            }}
            className={`${
              slab === 3 ? "bg-[#6091E2] border-[1px] text-white border-x-white" : "border-none"
            } text-center p-2  hover:bg-[#e1ebfa] hover:text-black cursor-pointer rounded-md`}
          >
            <h5 className="text-[14px] max-md:text-[16px] font-[500]">
              Slab 3 <span className="text-[14px] max-md:text-[12px]">(Premium)</span>
            </h5>
          </div>
          <div
            onClick={() => {
              navHandler(4, "pro");
            }}
            className={`${
              slab === 4 ? "bg-[#6091E2] border-[1px] text-white border-x-white" : "border-none"
            } text-center p-2  hover:bg-[#e1ebfa] hover:text-black cursor-pointer rounded-md`}
          >
            <h5 className="text-[14px] max-md:text-[16px] font-[500]">
              Slab 4 <span className="text-[14px] max-md:text-[12px]">(Pro)</span>
            </h5>
          </div>
        </div>
        <div className="">
          <div className="w-full py-3 px-4 bg-[#1c234721] grid grid-cols-[2fr_1fr_1fr] text-[#8393AE] text-[14px] rounded-t-lg  max-md:hidden max-md:text-[12px]">
          <span>API</span>
            <span>Price</span>
            {/* <span>Changed Price</span> */}
            <span>Change Price</span>
          </div>
        </div>
        <div className="overflow-y-auto">
          {slab === 1 && (
            <>
              <div className="px-2 border-[1px]">
                {loading ? (
                  <Loading />
                ) : (
                  plans?.type === "default" &&
                  Object.keys(plans?.default).map((plan, index) => (
                    <div
                      key={index}
                      className={`${
                        index % 2 === 0 ? "" : "bg-[#fbfbfb]"
                      } w-full p-4 grid grid-cols-[2fr_1fr_1fr] max-md:block max-md:text-[12px]`}
                    >
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Service:
                        </span>
                        {plan}
                      </p>
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Price:
                        </span>
                        {Object.values(plans.default)[index]}
                      </p>
                      <div>
                        {editPrice !== plan ? (
                          <p
                            onClick={() => handleEditPrice(plan)}
                            className={`underline text-blue-500 cursor-pointer ${
                              editPrice
                                ? "text-[#808080] pointer-events-none"
                                : ""
                            }`}
                          >
                            Edit
                          </p>
                        ) : (
                          <div className="relative flex items-center">
                            <input
                              placeholder="Enter Price"
                              min={0}
                              className={`${
                                prices[plan] ? "border-[#FF7D90]" : ""
                              } border-[1px] p-1 max-w-[200px] focus:outline-none w-full`}
                              value={prices[plan] || ""}
                              onChange={(e) => handlePriceChange(e, plan)}
                            />
                            <button
                              className="btn primary-button py-1 max-md:text-[12px] max-md:px-1 rounded-none"
                              disabled={!prices[plan]}
                              onClick={() =>
                                ammendPlans("default", plan, prices[plan])
                              }
                            >
                              Save
                            </button>

                            <i
                              className="fa fa-times-circle text-[20px] max-md:text-[14px] max-md:ml-0 ml-4 hover:text-red-500 duration-300 cursor-pointer"
                              onClick={() => setEditPrice(null)}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          {slab === 2 && (
            <>
              <div className="px-2 border-[1px]">
                {loading ? (
                  <Loading />
                ) : (
                  plans?.type === "standard" &&
                  Object.keys(plans?.standard).map((plan, index) => (
                    <div
                      key={index}
                      className={`${
                        index % 2 === 0 ? "" : "bg-[#fbfbfb]"
                      } w-full p-4 grid grid-cols-[2fr_1fr_1fr] max-md:block max-md:text-[12px]`}
                    >
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Service:
                        </span>
                        {plan}
                      </p>
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Price:
                        </span>
                        {Object.values(plans.standard)[index]}
                      </p>
                      <div>
                        {editPrice !== plan ? (
                          <p
                            onClick={() => handleEditPrice(plan)}
                            className={`underline text-blue-500 cursor-pointer ${
                              editPrice
                                ? "text-[#808080] pointer-events-none"
                                : ""
                            }`}
                          >
                            Edit
                          </p>
                        ) : (
                          <div className="relative flex items-center">
                            <input
                              placeholder="Enter Price"
                              min={0}
                              className={`${
                                prices[plan] ? "border-[#0082F6]" : ""
                              } border-[1px] p-1 max-w-[200px] focus:outline-none w-full`}
                              value={prices[plan] || ""}
                              onChange={(e) => handlePriceChange(e, plan)}
                            />
                            <button
                              className="btn primary-button py-1 max-md:text-[12px] max-md:px-1 rounded-none"
                              disabled={!prices[plan]}
                              onClick={() =>
                                ammendPlans("standard", plan, prices[plan])
                              }
                            >
                              Save
                            </button>
                            <i
                              className="fa fa-times-circle text-[20px] max-md:text-[14px] max-md:ml-0 ml-4 hover:text-red-500 duration-300 cursor-pointer"
                              onClick={() => setEditPrice(null)}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          {slab === 3 && (
            <>
              <div className="px-2 border-[1px]">
                {loading ? (
                  <Loading />
                ) : (
                  plans?.type === "premium" &&
                  Object.keys(plans?.premium).map((plan, index) => (
                    <div
                      key={index}
                      className={`${
                        index % 2 === 0 ? "" : "bg-[#fbfbfb]"
                      } w-full p-4 grid grid-cols-[2fr_1fr_1fr] max-md:block max-md:text-[12px]`}
                    >
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Service:
                        </span>
                        {plan}
                      </p>
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Price:
                        </span>
                        {Object.values(plans.premium)[index]}
                      </p>
                      <div>
                        {editPrice !== plan ? (
                          <p
                            onClick={() => handleEditPrice(plan)}
                            className={`underline text-blue-500 cursor-pointer ${
                              editPrice
                                ? "text-[#808080] pointer-events-none"
                                : ""
                            }`}
                          >
                            Edit
                          </p>
                        ) : (
                          <div className="relative flex items-center">
                            <input
                              placeholder="Enter Price"
                              min={0}
                              className={`${
                                prices[plan] ? "border-[#FF7D90]" : ""
                              } border-[1px] p-1 max-w-[200px] focus:outline-none w-full`}
                              value={prices[plan] || ""}
                              onChange={(e) => handlePriceChange(e, plan)}
                            />
                            <button
                              className="btn primary-button py-1 max-md:text-[12px] max-md:px-1 rounded-none"
                              disabled={!prices[plan]}
                              onClick={() =>
                                ammendPlans("premium", plan, prices[plan])
                              }
                            >
                              Save
                            </button>
                            <i
                              className="fa fa-times-circle text-[20px] max-md:text-[14px] max-md:ml-0 ml-4 hover:text-red-500 duration-300 cursor-pointer"
                              onClick={() => setEditPrice(null)}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          {slab === 4 && (
            <>
              <div className="px-2 border-[1px]">
                {loading ? (
                  <Loading />
                ) : (
                  plans?.type === "pro" &&
                  Object.keys(plans?.pro).map((plan, index) => (
                    <div
                      key={index}
                      className={`${
                        index % 2 === 0 ? "" : "bg-[#fbfbfb]"
                      } w-full p-4 grid grid-cols-[2fr_1fr_1fr] max-md:block max-md:text-[12px]`}
                    >
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Service:
                        </span>
                        {plan}
                      </p>
                      <p className="max-md:grid grid-cols-[100px_1fr]">
                        <span className="hidden max-md:block font-[600]">
                          Price:
                        </span>
                        {Object.values(plans.pro)[index]}
                      </p>
                      <div>
                        {editPrice !== plan ? (
                          <p
                            onClick={() => handleEditPrice(plan)}
                            className={`underline text-blue-500 cursor-pointer ${
                              editPrice
                                ? "text-[#808080] pointer-events-none"
                                : ""
                            }`}
                          >
                            Edit
                          </p>
                        ) : (
                          <div className="relative flex items-center">
                            <input
                              placeholder="Enter Price"
                              min={0}
                              className={`${
                                prices[plan] ? "border-[#FF7D90]" : ""
                              } border-[1px] p-1 max-w-[200px] focus:outline-none w-full`}
                              value={prices[plan] || ""}
                              onChange={(e) => handlePriceChange(e, plan)}
                            />
                            <button
                              className="btn primary-button py-1 max-md:text-[12px] max-md:px-1 rounded-none"
                              disabled={!prices[plan]}
                              onClick={() =>
                                ammendPlans("pro", plan, prices[plan])
                              }
                            >
                              Save
                            </button>
                            <i
                              className="fa fa-times-circle text-[20px] max-md:text-[14px] max-md:ml-0 ml-4 hover:text-red-500 duration-300 cursor-pointer"
                              onClick={() => setEditPrice(null)}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
